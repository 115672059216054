import React from 'react';
import { Divider } from 'semantic-ui-react';

import styles from './Home.module.css';

export const Home: React.FC = () => {
  return (
    <div>
      <div className={styles.container} style={{marginTop: '90px'}}>

        <Hero
          heroText={'Virtual classes & events that keep you connected & energized.'}
          subText={
            ''
          }
          image={'/right_img.png'}
        />
        <Divider horizontal />
        <div
          style={{
            padding: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

        </div>
      </div>
    </div>
  );
};

// const Feature = ({
//   icon,
//   text,
//   title,
// }: {
//   icon: string;
//   text: string;
//   title: string;
// }) => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         flex: '1 1 0px',
//         flexDirection: 'column',
//         alignItems: 'center',
//         padding: '10px',
//         minWidth: '180px',
//       }}
//     >
//       <Icon fitted size="huge" name={icon as any} />
//       <h4 className={styles.featureTitle}>{title}</h4>
//       <div className={styles.featureText}>{text}</div>
//     </div>
//   );
// };

const Hero = ({
  heroText,
  subText,
  action,
  image,
  color,
}: {
  heroText?: string;
  subText?: string;
  action?: React.ReactNode;
  image?: string;
  color?: string;
}) => {
  return (
    <div className={`${styles.hero} ${color === 'green' ? styles.green : ''}`}>
      <div className={styles.heroInner}>
        <div style={{ padding: '30px', flex: '1 1 0' }}>
          <div className={styles.heroText}>{heroText}</div>
          <div className={styles.subText}>{subText}</div>
          {action}
        </div>
        <div
          style={{
            flex: '1 1 0',
          }}
        >
          <img
            alt="hero"
            style={{ width: '100%', borderRadius: '10px' }}
            src={image}
          />
        </div>
      </div>
    </div>
  );
};
