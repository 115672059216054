import React from 'react';


export const Footer = () => (
  <div style={{ margin: '1em', fontSize: '14px', color: 'white', width: '100%', position: 'absolute', bottom: '20px' }}>
    <a target="_blank"  rel="noreferrer" style={{ color: 'white' }} href="https://docs.google.com/document/d/e/2PACX-1vRoSsaL7iOgv1JcHr25HPT_YKD4noD4OiIiZYhfcwHg-8h425GpLvF5l4zUcNscJmN2_9Rm5qOU06hA/pub">
      Terms
    </a>
    <div style={{margin: 'auto', paddingTop: '30px'}}>
      For any questions, email hello@alivefitandfree.com or call 480-458-7497.
    </div>
  </div>
);
