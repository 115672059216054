import React from 'react';
import { DropdownProps, Dropdown, Label, Input } from 'semantic-ui-react';
import { debounce,  getYouTubeResults, getMediaPathServer } from '../../utils';
import { YouTubeSearchResult } from '../SearchResult/SearchResult';

interface ComboBoxProps {
  setMedia: Function;
  currentMedia: string;
  getMediaDisplayName: Function;
  launchMultiSelect: Function;
  mediaPath: string | undefined;
  streamPath: string | undefined;
  disabled?: boolean;
}

export class ComboBox extends React.Component<ComboBoxProps> {
  state = {
    inputMedia: undefined as string | undefined,
    results: undefined,
    resultsS3: undefined,
    loading: false,
    showInput: false,
    selectS3: -1,
    lastResultTimestamp: Number(new Date()),
  };
  debounced: any = null;

  setMedia = (e: any, data: DropdownProps) => {
    // window.setTimeout(
    //   () => this.setState({ inputMedia: undefined, results: undefined }),
    //   100
    // );
    this.props.setMedia(e, data);
    if(this.state.showInput === false) {
      this.setState({selectS3: data.value});
    }
  };
  changeResoure = (e: any, data: DropdownProps ) => {
     if(data.value === 1) {
       this.setState({showInput: false, inputMedia: undefined});

     }
     else {
       this.setState({showInput: true, inputMedia: undefined});
     }
  }

  componentWillMount() {
    this.getList();

  }

  getList = async () => {
    const data = await getMediaPathServer('');
    if(!this.props.currentMedia.startsWith('https://wwww.youtube.com')) {}

    this.setState({
      loading: false,
      resultsS3: data,

    });
  }


  doSearch = async (e: any) => {
    e.persist();
    this.setState({ inputMedia: e.target.value }, () => {

      if (!this.debounced) {
        this.debounced = debounce(async () => {
          this.setState({ loading: true });
          const query: string = this.state.inputMedia || '';
          let timestamp = Number(new Date());
          let results: JSX.Element[] | undefined = undefined;
          if (query === '' || (query && query.startsWith('http'))) {
            // alert(1222);
            // results = examples.map((option: any) => (
            //   <Menu.Item
            //     key={option.url}
            //     onClick={(e: any) => this.setMedia(e, { value: option.url })}
            //   >
            //     {option.url}
            //   </Menu.Item>
            // ));
          } else {

            const data = await getYouTubeResults(query);
            results = data.map((result) => (
              <YouTubeSearchResult
                key={result.url}
                {...result}
                setMedia={this.setMedia}
              />
            ));
          }
          if (timestamp > this.state.lastResultTimestamp) {
            this.setState({
              loading: false,
              results,
              lastResultTimestamp: timestamp,
            });
          }
        }, 500);
      }
      this.debounced();
    });
  };



  render() {
    const { currentMedia, getMediaDisplayName } = this.props;
    const { resultsS3} = this.state;
    const options = [
      { key: 1, text: 'Amazon S3', value: 1 },
      { key: 2, text: 'Youtube', value: 2 },
    ]
    return (
      <div style={{ display: 'flex' }}>
        <Label style={{ width: '160px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          NOW WATCHING
        </Label>

        <Dropdown
          placeholder='Select source'
          fluid
          search
          style={{marginRight: '5px', width: '150px', 'zIndex': 9999}}
          selection
          defaultValue={1}
          onChange={this.changeResoure}
          options={options}
        />

        {!this.state.showInput && (
        <Dropdown
          placeholder='Select Video'
          fluid
          search
          defaultValue={this.props.currentMedia}

          style={{marginRight: '5px', 'zIndex': 9999}}
          selection
          onChange={this.setMedia}
          options={resultsS3}
        />
        )}
        {this.state.showInput && (
          <Input
            style={{ flexGrow: 1, marginRight: '5px' }}
            inverted
            fluid
            focus
            disabled={this.props.disabled}
            onChange={this.doSearch}
            onFocus={(e: any) => {
              e.persist();
              this.setState(
                {
                  inputMedia: getMediaDisplayName(currentMedia).startsWith('https://www.youtube.com/') ?  getMediaDisplayName(currentMedia): '' ,
                },
                () => {
                  if (
                    !this.state.inputMedia ||
                    (this.state.inputMedia &&
                      this.state.inputMedia.startsWith('http'))
                  ) {
                    this.doSearch(e);
                  }
                }
              );
              setTimeout(() => e.target.select(), 100);
            }}
            onBlur={() =>
              setTimeout(
                () =>
                  this.setState({ inputMedia: undefined, results: undefined }),
                100
              )
            }
            onKeyPress={(e: any) => {
              if (e.key === 'Enter') {
                this.setMedia(e, {
                  value: this.state.inputMedia,
                });
              }
            }}

            loading={this.state.loading}
            placeholder="Enter YouTube link."
            value={
              this.state.inputMedia !== undefined
                ? this.state.inputMedia
                : (getMediaDisplayName(currentMedia).startsWith('https://www.youtube.com/') ?  getMediaDisplayName(currentMedia): '')
            }
          />
        )}
      </div>

    );
  }
}
