import React from 'react';
import { Modal, Button, Form, Message } from 'semantic-ui-react';


import { serverPath } from '../../utils';

export class NewRoomModal extends React.Component<{
  closeModal: Function;
}> {
  public state = { roomName: '', error: '' };
  addRoom = async (roomName: string) => {
    if(roomName === '') {
      this.setState({error: 'Please enter room name'})
    }
    else {
      const response = await window.fetch(serverPath + '/createRoom', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({'roomName' : roomName }),
      });
      const data = await response.json();
      const { name } = data;

      window.location.href = '/#' + name;
      window.location.reload();
    }

  };


  render() {
    const { closeModal } = this.props;
    return (
      <Modal size="tiny" open={true} onClose={closeModal as any}>
        <Modal.Header>
          Create a new Room
        </Modal.Header>
        <Modal.Content>
          {this.state.error && (
            <Message color="red" header="Error" content={this.state.error} />
          )}
          <Form>
            <Form.Field>
              <label>Room Name</label>
              <input
                placeholder="Room Name"
                value={this.state.roomName}
                onChange={(e) => this.setState({ roomName: e.target.value })}
              />
            </Form.Field>
              <Button
                type="submit"
                onClick={() =>
                  this.addRoom(this.state.roomName)
                }
              >
                Create
              </Button>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}
